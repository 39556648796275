
import React, { useState, useEffect, useRef } from "react";

import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Cursantes from "layouts/cartera-clientes/cursantes/Index";
import Cliente from "layouts/cartera-clientes/empresas/Cliente";
import { USER_INSTRUCTOR, USER_ADMIN, USER_LIDER_CAPACITACION, USER_LIDER_SIPA, USER_COTIZACIONES } from "constans";

function CarteraClientes() {

    const navigate = useNavigate();
    // const scrollRef = useRef(React.createRef());
    const [valueTab, setValueTab] = useState('0');
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));

    useEffect(() => {
        console.log(userData);

        if (userData.user.admin === USER_LIDER_CAPACITACION) {
            setValueTab('1')
        }

    }, []);


    return (
        <DashboardLayout>
            <MDBox pb={3} >
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox pt={3}>
                                <MDBox pb={3} px={3}>
                                    <MDBox>
                                        <Grid pb={3}>
                                            <Tabs
                                                value={valueTab}
                                                textColor="primary"
                                                indicatorColor="primary"
                                                onChange={(event, newValue) => {
                                                    setValueTab(newValue);
                                                }}
                                            >
                                                {(userData.user.admin === USER_LIDER_CAPACITACION) ?
                                                    null
                                                    :                                                    
                                                    <Tab label="Empresas" value="0" />                                                    
                                                }
                                                <Tab label="Independientes" value="1" />
                                            </Tabs>

                                            <Grid md={12} pt={3} pb={3} spacing={6}>
                                                {valueTab === "0" ? <Cliente /> : null}
                                                {valueTab === "1" ? <Cursantes /> : null}
                                            </Grid>

                                        </Grid>

                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout >
    );
}

CarteraClientes.propTypes = {
};

export default CarteraClientes;

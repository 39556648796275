import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import moment from 'moment';

import { Document, PDFDownloadLink, pdf, PDFViewer } from '@react-pdf/renderer';
import domtoimage from 'dom-to-image';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import Modal from 'react-modal';

import PropTypes from 'prop-types';
import { useSearchParams, useNavigate } from 'react-router-dom';

import QRCode from 'qrcode';

import NewCertGenerator from "tools/NewCertGenerator";
import CertGeneratorPDF from "tools/CertGeneratorPDF"

import Message from "../../tools/Message";

import imgCredencial1 from "../../tools/credencial_fondo.jpg";
import imgCredencial2 from "../../tools/credencial_fondo_2.jpg";
import imgCredencialBack2 from "../../tools/credencial_fondo_back_2.jpg";
import NoImg from "../../tools/no-img.png";


import { Get, Del, GetGuest, Post } from "../../tools/Helper";

function Participantes({ dataCursoProgramado, setDataCursoProgramado, cursantesProgramados }) {
  const [status, setStatus] = useState({ status: null, message: "" });
  const [domain, setDomain] = useState('');
  const [qr, setQr] = useState(null);
  const [qrPart, setQrPart] = useState(null);
  const [urlCurso, setUrlCurso] = useState(null);
  const [data, setData] = useState(cursantesProgramados);
  const [loading, setLoading] = useState(false);
  const [dataCertificado, setDataCertificado] = useState(null);
  const [descargando, setDescargando] = useState('DESCARGAR TODOS LOS CERTIFICADOS');

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [credModal, setCredModal] = useState(false);

  const [blob, setBlob] = useState(null);

  const downloadCertificadoDC3Chunk = async (row) => {
    const generateQR = async (item) => {
      const qrData = await QRCode.toDataURL(`https://certificados.paymun.mx/${item.folio_unico_registro}/${item.token_certificado}-${item.cursante.id_cursante}`);
      return qrData;
    };

    const pdfPages = row.map(async (item) => {
      const qrCert = await generateQR(item);
      return (
        <>
          <CertGeneratorPDF data={item} qr={qrCert} />
          <NewCertGenerator data={item} qr={qrCert} />
        </>
      );
    });

    const pages = await Promise.all(pdfPages);

    const blobCert = await pdf(
      <Document>
        {pages}
      </Document>
    ).toBlob();
    setDescargando('DESCARGAR TODOS LOS CERTIFICADOS');
    const url = URL.createObjectURL(blobCert);
    const a = document.createElement('a');
    a.href = url;
    a.download = `certificados_${dataCursoProgramado.clave_curso_programado}.pdf`;
    a.click();
  };

  const getdownloadCertificadoDC3Chunk = async () => {
    setDescargando('DESCARGANDO...');
    setStatus({ status: null, message: "" });
    const request = await Get(`certificados-cursantes-programado/${dataCursoProgramado.id_curso_programado}`);
    if (request.status === "success") {
      const row = request.data;
      downloadCertificadoDC3Chunk(row);
    } else {
      setStatus({ status: request.status, message: "Error." });
    }
  }

  const downloadFCertificadoDC3 = async () => {
    const tmp = await pdf(
      <Document>
        <CertGeneratorPDF data={dataCertificado} qr={qr} />
        <NewCertGenerator data={dataCertificado} qr={qr} />
      </Document>
    ).toBlob();

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(tmp);
    link.download = `certificado_${dataCertificado.nombre_cursante}_${dataCertificado.folio_unico_registro}.pdf`;
    link.click();
  }

  const openModalCred = () => {
    setCredModal(true);
  }

  const openModal = () => {
    setModalIsOpen(true);
  }
  const closeModal = () => {
    setModalIsOpen(false);
    setCredModal(false);
  }

  const downloadPDF = async (idCursanteProgramado, type) => {
    setStatus({ status: null, message: "" });
    setLoading(true);
    const request = await Get(`certificado-cursante-programado/${idCursanteProgramado}`);

    if (request.status === "success") {
      const row = request.data;

      const folio = row.folio_unico_registro.toString().replaceAll("/", "|");

      const qrData = await QRCode.toDataURL(`https://certificados.paymun.mx/${folio}/${row.token_certificado}-${row.cursante.id_cursante}`);

      setDataCertificado(row);
      setQr(qrData);
      if (type === 'certificado') {
        openModal();
      } else if (type === 'credencial') {
        openModalCred();
      }

      setLoading(false);
    } else {
      setStatus({ status: request.status, message: "Error." });
      setLoading(false);
    }
  }

  const downloadCred = () => {
    domtoimage
      .toJpeg(document.getElementById("credencial"), { quality: 0.95 })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `credencial_${dataCertificado.folio_unico_registro}_${dataCertificado.nombre_cursante}.jpeg`;
        link.href = dataUrl;
        link.click();
      });

    if (dataCertificado.curso?.tipo_credencial === 1) {
      domtoimage
        .toJpeg(document.getElementById("credencial_back"), { quality: 0.95 })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `credencial_back_${dataCertificado.folio_unico_registro}_${dataCertificado.nombre_cursante}.jpeg`;
          link.href = dataUrl;
          link.click();
        });
    }
  }

  const navigate = useNavigate();
  const navigateResult = (row) => {
    window.open(`/evaluacion-curso?tk=${dataCursoProgramado.token_qr}&type=final&email=${row.correo}&attempt=${row.num_oportunidades > 1 ? row.num_oportunidades - 1 : row.num_oportunidades}`, '_blank', 'noreferrer');
    //  navigate(`/evaluacion-curso?tk=${dataCursoProgramado.token_qr}&type=final&email=${row.correo}&attempt=${row.num_oportunidades - 1}`);        

  }


  const buttonPdf = (row) => <MDButton style={{ borderRadius: 10, color: 'white' }} variant="gradient" color="info" iconOnly onClick={() => downloadPDF(row.id_cursante_programado, 'certificado')}><Icon>picture_as_pdf</Icon></MDButton>;
  const buttonPdfCredencial = (row) => <MDButton style={{ borderRadius: 10, color: 'white' }} variant="gradient" color="info" iconOnly onClick={() => downloadPDF(row.id_cursante_programado, 'credencial')}><Icon>picture_as_pdf</Icon></MDButton>;
  const buttonReprobado = (row) => <MDButton onClick={() => navigateResult(row)} style={{ borderRadius: 10, color: 'white', width: 100 }} variant="gradient" color="error" iconOnly >NO APROBADO</MDButton>;
  const buttonAprobado = (row) => <MDButton onClick={() => navigateResult(row)} style={{ borderRadius: 10, color: 'white', width: 100 }} variant="gradient" color="success" iconOnly > {row.calificacion_final ? 'APROBADO' : '-'}</MDButton>;

  const columns = [
    {
      name: 'Nombre Completo',
      selector: row => row.nombre_completo,
    },
    {
      name: 'CURP',
      selector: row => row.curp,
    },
    {
      name: 'Correo/Telefono',
      selector: row => row.correo ? row.correo : row.telefono,
    },
    {
      name: 'Diagnóstica',
      selector: row => row.calificacion_diagnostica,
    },
    {
      name: 'Final',
      selector: row => parseInt(row.calificacion_final, 10) < 7 ? buttonReprobado(row) : buttonAprobado(row),
    },
    {
      name: 'Servicio',
      selector: row => row.resultado_encuesta ? 'Contestado' : 'No contestado',
    },
    {
      name: 'PDF.',
      selector: row => parseInt(row.calificacion_final, 10) > 7 ? buttonPdf(row) : '',
      grow: 0,
      compact: true,
      width: "60px",
    },
    {
      name: 'Cred.',
      selector: row => row.cred === "1" && parseInt(row.calificacion_final, 10) > 7 ? buttonPdfCredencial(row) : "",
      grow: 0,
      compact: true,
      width: "60px",
    },
  ];

  const tableData = {
    columns,
    data
  };

  const generateQRCurso = async () => {
    const fullpath = window.location.href;
    const regex = window.location.pathname;
    const domainName = fullpath.replace(regex, '');
    setDomain(domainName);
    const url = `${domainName}/alta-cursantes-curso?tk=${dataCursoProgramado.token_qr}`;
    const qrData = await QRCode.toDataURL(url);
    setUrlCurso(url);
    await setQrPart(qrData);
  }

  const copyLink = () => {
    navigator.clipboard.writeText(urlCurso);
    setStatus({ status: 'success', message: 'Enlace copiado en portapapeles...' });
  }

  const navigateAdminParticipantes = () => {
    const fullpath = window.location.href;
    const regex = window.location.pathname;
    const domainName = fullpath.replace(regex, '');
    const url = `${domainName}/admin-cursantes-curso?tk=${dataCursoProgramado.token_qr}`;
    window.open(url, '_blank');
  }

  useEffect(() => {
    generateQRCurso();
  }, []);

  return (
    <MDBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <Grid item xs={6}>
              -
            </Grid>
            <MDBox display="flex" justifyContent="space-between" tem xs={6}>
              <p style={{ lineHeight: '1.4rem', fontSize: '1rem' }}>Escaneé el código QR o comparta este link para agregar participantes:</p>
              <div>
                <img src={qrPart} alt="qr" width={100} />
              </div>
              <div>
                <p style={{ lineHeight: '1.4rem', fontSize: '1rem' }}>{urlCurso}</p>
                <MDButton variant="gradient" color="success" fullWidth style={{ color: 'white' }} onClick={() => copyLink()} >
                  Copiar enlace
                </MDButton>
                <MDButton variant="gradient" mt={3} color="dark" fullWidth style={{ color: 'white', marginTop: 5 }} onClick={() => navigateAdminParticipantes()} >
                  Administrar participantes
                </MDButton>
                <MDButton variant="gradient" mt={3} color="info" fullWidth style={{ color: 'white', marginTop: 5 }} onClick={() => getdownloadCertificadoDC3Chunk()} >
                  {descargando}
                </MDButton>
              </div>

            </MDBox>
          </MDBox>
          <Message status={status} />
          <MDBox>
            <DataTableExtensions
              {...tableData}
              export={false}
              print={false}
              filterPlaceholder="Buscar..."
            >
              <DataTable
                columns={columns}
                data={cursantesProgramados}
                pagination
                paginationComponentOptions={{
                  rowsPerPageText: 'Participantes por página:',
                  rangeSeparatorText: 'de',
                  noRowsPerPage: false,
                  selectAllRowsItem: true,
                  selectAllRowsItemText: 'Todos'
                }}
                noDataComponent={<MDBox py={5}>No se encontraron participantes.</MDBox>}
              />
            </DataTableExtensions>
          </MDBox>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Modal Certificado"
          >
            <Grid container spacing={6} pb={2}>
              <Grid item xs={6}>
                <MDButton variant="gradient" color="success" fullWidth onClick={() => downloadFCertificadoDC3()}>
                  Descargar
                </MDButton>
              </Grid>
              <Grid item xs={6} textAlign="right" >
                <MDButton variant="gradient" color="error" fullWidth onClick={() => closeModal()}>
                  Cerrar
                </MDButton>
              </Grid>
            </Grid>
            {dataCertificado && qr ?
              <PDFViewer width="100%" height="96%" scale="3.0">
                <Document>
                  <CertGeneratorPDF data={dataCertificado} qr={qr} />
                  <NewCertGenerator data={dataCertificado} qr={qr} />
                </Document>
              </PDFViewer> : "Cargando..."
            }
          </Modal>
          <Modal
            isOpen={credModal}
            onRequestClose={closeModal}
          >
            <Grid container spacing={6} mb={2}>
              <Grid item xs={6}>
                <MDButton variant="gradient" color="success" fullWidth onClick={() => downloadCred()}>
                  Descargar
                </MDButton>
              </Grid>
              <Grid item xs={6} textAlign="right" >
                <MDButton variant="gradient" color="error" fullWidth onClick={() => closeModal()}>
                  Cerrar
                </MDButton>
              </Grid>
            </Grid>
            <div className={`cont-${(dataCertificado?.curso?.tipo_credencial === 0) ? 'vrtcl' : 'hrzntl'}`} >
              {dataCertificado && qr ?

                <>
                  <div className={`border1 ${(dataCertificado.curso?.tipo_credencial === 0) ? 'pos-rel' : 'pos-hrzntl'}`} id="credencial" >
                    {
                      (dataCertificado.curso.tipo_credencial === 0) ?
                        <img
                          alt="bg"
                          src={imgCredencial1}
                          className="pos-rel"
                        />
                        :
                        <img
                          alt="bg"
                          src={imgCredencial2}
                          className="pos-rel"
                        />
                    }

                    <img
                      alt="foto"
                      src={dataCertificado.cursante.evidencias && dataCertificado.cursante.evidencias.foto ? dataCertificado.cursante.evidencias.foto : NoImg}
                      className="avatar"
                    />
                    <img src={qr} alt="qr" className="qr" />

                    <div className="nombre-curso">
                      <span className="bold">{dataCertificado.curso.nombre}</span>
                    </div>

                    <div className="nombre-acreditado">
                      <span className="label">Nombre del acreditado</span>
                      <br />
                      <span className="bold">{dataCertificado.nombre_cursante}</span>
                    </div>
                    <div className="curso">
                      <span className="label">Curso:</span>
                      <br />
                      <span className="bold">{dataCertificado.curso.nombre}</span>
                    </div>
                    <div className="fecha-vigencia">
                      <span className="bold">{moment(dataCertificado.vigencia_fecha).format('DD/MM/YYYY')}</span>
                    </div>

                    <div className="fecha-finalizacion">
                      <span className="label">Fecha de finalización:</span>
                      <br />
                      <span className="bold">{dataCertificado.fecha_termino}</span>
                    </div>

                    <div className="no-certificacion">
                      <span className="label">No. de certificación:</span>
                      <br />
                      <span className="bold">{dataCertificado.folio_unico_registro}</span>
                    </div>

                    <div className="rfc">
                      <span className="bold">{dataCertificado.cursante.rfc}</span>
                    </div>

                    <div className="version">
                      <span className="bold">{dataCertificado.curso.version}</span>
                    </div>

                    {
                      (dataCertificado.instructor.firma) ?
                        <div className="firma-instructor">
                          <img src={dataCertificado.instructor.firma} alt="firma" />
                        </div>
                        :
                        null
                    }

                    <div className="nombre-instructor">
                      <span className="bold">{dataCertificado.instructor.nombre_completo}</span>
                    </div>

                    <div className="registro-instructor">
                      <span className="bold">{dataCertificado.instructor.registro_1}</span>
                    </div>

                    <div className="desarrollo">Desarrollo de Talento</div>
                    <div className="humano">Humano</div>
                  </div>

                  {
                    (dataCertificado.curso?.tipo_credencial === 1)
                      ?

                      <div className={`border1 ${(dataCertificado.curso?.tipo_credencial === 0) ? 'pos-rel' : 'pos-hrzntl'}`} id="credencial_back" >
                        <img
                          alt="bg"
                          src={imgCredencialBack2}
                          className="pos-rel"
                        />

                        <div className="folio-back">
                          <span className="bold">{dataCertificado.folio_unico_registro}</span>
                        </div>

                        <div className="nombre-instructor-back">
                          <span className="bold">{dataCertificado.instructor.nombre_completo}</span>
                        </div>

                        <div className="registro-instructor-back">
                          <span className="bold">{dataCertificado.instructor.registro_1}</span>
                        </div>
                      </div>

                      : null
                  }

                </>
                :

                "Cargando..."
              }
            </div>
          </Modal>
        </Grid>
      </Grid>
    </MDBox>
  );
}

Participantes.propTypes = {
  dataCursoProgramado: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  setDataCursoProgramado: PropTypes.func.isRequired,
  cursantesProgramados: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

export default Participantes;
